body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  background: #0B1426;
}

.displayCenter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.displayStart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.displayEnd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}

.displaySpacebetween {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.displaySpacearound {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.logoImg {
  width: 110px;
}

.bgx1 {
  -webkit-animation: rotating 26s linear infinite;
  animation: rotating 26s linear infinite;
}

.bgx2 {
  -webkit-animation: rotating2 6s linear infinite;
  animation: rotating2 6s linear infinite;
}

.crypto {
  -webkit-animation: rotating3 3s linear infinite;
  animation: rotating3 3s linear infinite;
}

@-webkit-keyframes rotating3 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotating3 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotating {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotating2 {
  from {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes rotating2 {
  from {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.sliderBox {
  padding: 2%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 10px;
  background-color: #121212;
  border-top: 3px solid #e35f24;
  text-align: center;
}

.sliderBox img {
  width: auto;
  max-width: 165px;
}

.apexcharts-tooltip {
  border-radius: 5px;
  cursor: default;
  font-size: 14px;
  left: 62px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  z-index: 12;
  -webkit-transition: 0.15s ease all;
  transition: 0.15s ease all;
  background: #194129;
}

.chartBox {
  position: relative;
  width: 190px !important;
}

.apexcharts-canvas {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 190px !important;
}

.shadeBox {
  right: auto;
  left: 0;
  width: 450px;
  /* bottom: auto; */
  -webkit-filter: blur(230px);
  filter: blur(230px);
  height: 550px;
  opacity: 0.55;
  top: 0;
  z-index: -1;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, #ff6600 0%, #ff66004f 95.78%);
}

.orangeshadeBox {
  right: 0;
  left: auto;
  width: 450px;
  /* bottom: auto; */
  -webkit-filter: blur(230px);
  filter: blur(230px);
  height: 450px;
  opacity: 0.55;
  top: 51%;
  z-index: 99;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, #ff6600 0%, #ff66008a 95.78%);
}

.orangeshadeBox1 {
  left: 10%;
  right: auto;
  width: 600px;
  /* bottom: auto; */
  opacity: 0.6;
  -webkit-filter: blur(177px);
  filter: blur(177px);
  height: 200px;
  border-radius: 1000px;
  top: 29%;
  z-index: 99;
  position: absolute;
  -webkit-transform: rotate(132deg);
  transform: rotate(132deg);
  background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, #ff6600 0%, rgba(255, 102, 0, 0.78));
}

.purpleBox {
  left: 0;
  right: auto;
  width: 300px;
  -webkit-filter: blur(230px);
  filter: blur(230px);
  height: 300px;
  opacity: 0.55;
  top: 50%;
  z-index: 99;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, #4800ff 0%, rgba(46, 17, 239, 0.87));
}

.purpleBox1 {
  right: 0;
  left: auto;
  width: 300px;
  bottom: auto;
  -webkit-filter: blur(260px);
  filter: blur(260px);
  height: 700px;
  opacity: 0.6;
  top: 0;
  z-index: 99;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, #4800ff 0%, rgba(46, 17, 239, 0.87) 95.78%);
}

.glowing {
  -webkit-animation: glowing 2s linear infinite;
  animation: glowing 2s linear infinite;
}

@-webkit-keyframes glowing {
  0% {
    -webkit-box-shadow: 0px 0px 40px 25px rgba(255, 102, 0, 0.768627);
    box-shadow: 0px 0px 40px 25px rgba(255, 102, 0, 0.768627);
  }

  50% {
    -webkit-box-shadow: 0px 0px 45px 38px rgba(255, 102, 0, 0.66);
    box-shadow: 0px 0px 45px 38px rgba(255, 102, 0, 0.66);
  }

  100% {
    -webkit-box-shadow: 0px 0px 40px 25px rgba(255, 102, 0, 0.768627);
    box-shadow: 0px 0px 40px 25px rgba(255, 102, 0, 0.768627);
  }
}

@keyframes glowing {
  0% {
    -webkit-box-shadow: 0px 0px 40px 25px rgba(255, 102, 0, 0.768627);
    box-shadow: 0px 0px 40px 25px rgba(255, 102, 0, 0.768627);
  }

  50% {
    -webkit-box-shadow: 0px 0px 45px 38px rgba(255, 102, 0, 0.66);
    box-shadow: 0px 0px 45px 38px rgba(255, 102, 0, 0.66);
  }

  100% {
    -webkit-box-shadow: 0px 0px 40px 25px rgba(255, 102, 0, 0.768627);
    box-shadow: 0px 0px 40px 25px rgba(255, 102, 0, 0.768627);
  }
}

.livetrackBox::-webkit-scrollbar {
  display: none;
}

.livetrackBox {
  overflow: auto;
  max-height: 750px;
}

.sideMenuBox::-webkit-scrollbar {
  display: none;
}

.sideMenuBox {
  overflow: auto;
  height: calc(100vh - 201px);
}

.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: -1;
  position: fixed;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-tap-highlight-color: transparent;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.trackbutton {
  height: 41px !important;
  padding: 10px 18px !important;
  font-size: 14px;
  background: transparent !important;
  border: 1px solid #F39200 !important;
  font-weight: 600;
  line-height: 21px;
  /* margin-right: 10px; */
  border-radius: 10px;
  background-color: transparent !important;
  color: #F39200 !important;
}

.greenBox {
  width: 40px;
  height: 40px;
  background: #5cd748;
  border-radius: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 33px;
}

.redBox {
  width: 40px;
  height: 40px;
  background: #ff3939;
  border-radius: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 33px;
}

.barchart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  color: #000 !important;
}

.barchart .apexcharts-tooltip.apexcharts-theme-light {
  color: #000 !important;
}

svg.MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active {
  color: #F39200 !important;
}

.TabBox .buttonBoxtab button {
  color: rgba(255, 255, 255, 0.6);
  width: 80%;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 0px 16px;
  font-size: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  white-space: pre;
  text-decoration: none;
}

@media (max-width: 767px) {
  .TabBox .buttonBoxtab button {
    font-size: 14px;
  }
}

.TabBox .buttonBoxtab button.active {
  color: rgba(255, 255, 255, 0.6);
  font-family: "Ubuntu", sans-serif;
  border-bottom: 3px solid #f5c843;
}

.webkitcss input[type="number"]::-webkit-inner-spin-button,
.webkitcss input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

@media (max-width: 1024px) {
  .shadeBox {
    display: none;
  }

  .logoImg {
    width: 80px;
  }
}

@media (max-width: 991px) {
  .shadeBox {
    display: none;
  }

  .logoImg {
    width: 80px;
  }
}

@media (max-width: 767px) {
  .shadeBox {
    display: none;
  }

  .logoImg {
    width: 80px;
  }

  .sliderBox img {
    width: auto;
    max-width: 135px;
  }
}


.MuiPickersDay-dayDisabled {
  color:#818181 !important;
}

